<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { useAirlinePartner, useNavigation, useTracker, useRouteBaseName, useRoute } from '#imports';

interface IProps {
  limit: number;
}

const props = defineProps<IProps>();

const { airlinePartners, getAirlineLogoUrl, getAirlinePath } = await useAirlinePartner(props.limit);
const { jitsuEvent } = useTracker();
const getRouteBaseName = useRouteBaseName();
const route = useRoute();
</script>

<template>
  <div
    v-if="!isEmpty(airlinePartners)"
    class="flex flex-col items-center w-full"
  >
    <div>
      <h2 class="font-bold text-gray-darkest mb-15">
        {{ $t('global.airlinepartnersheader') }}
      </h2>
    </div>
    <div class="flex gap-20 w-full overflow-x-auto airlines-wrapper mb-20">
      <NuxtLink
        v-for="(airline, index) in airlinePartners"
        :key="index"
        :to="getAirlinePath(airline)"
        external
        no-rel
        @click="
          jitsuEvent('user-click-button', {
            event_name: 'airlinepartners-defaultlist',
            object_name: 'airline-partners',
            object_parameter: 'airline',
            airline: airline.code,
            screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
          })
        "
      >
        <NuxtImg
          :alt="airline.name + ' Logo Images'"
          :src="getAirlineLogoUrl(airline.code)"
          data-testid="airlinePartners-logo-button"
          loading="lazy"
          height="35"
          width="35"
          class="max-w-none"
        />
      </NuxtLink>
    </div>

    <ApzButton
      tag="nuxt-link"
      type="primary"
      variant="outline"
      data-testid="airlinePartners-seeAllAirline-button"
      :to="useNavigation().navigationPath('/airlines')"
      external
      no-rel
      class="!h-40 text-small"
      @click="
        jitsuEvent('user-click-button', {
          event_name: 'airlinepartners-seemore',
          object_name: 'airline-partners',
          object_parameter: 'see-all',
          screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
        })
      "
    >
      {{ $t('global.seeairlinepartners') }}
    </ApzButton>
  </div>
</template>

<style scoped>
.airlines-wrapper {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.airlines-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
